import * as React from 'react';
import { Link } from 'gatsby';
import * as styles from './showcase-page.module.css';

function ShowcasePage({ children }) {
  return (
    <>
      <div className={styles.header}>
        <Link to="/#showcase" className={styles.button}>
          <span className={`material-icons ${styles.clickIcon}`}>
            chevron_left
          </span>
        </Link>
      </div>

      <div className={styles.siteContainer}>{children}</div>
    </>
  );
}

export default ShowcasePage;
